<template>
  <el-dialog
    :visible.sync="visible"
    :title="mode ? '新建题库' : '修改题库'"
    class="yt-dialog yt-dialog-default"
    :close-on-click-modal="false"
    :before-close="close"
    @closed="closed"
  >
    <el-form ref="form" :model="formData" :rules="ruleValidate" label-width="132px" class="yt-form yt-dialog-form">
      <el-form-item label="题库名称" prop="name">
        <el-input v-model="formData.name" placeholder="请输入题库名称" />
      </el-form-item>
      <el-form-item label="标签">
        <div class="tag-wrapper">
          <template v-for="(tab, index) in formData.tabs">
            <el-select
              v-model="formData.tabs[index]"
              placeholder="输入/选择"
              :key="index"
              :style="{
                marginRight: index + (1 % 3) === 0 ? '0' : '10px',
                marginBottom: index > formData.tabs.length - 4 ? '0' : '10px'
              }"
              filterable
              @visible-change="resetSelect"
              @mouseenter.native="tabDeleteVisible = index"
              @mouseleave.native="tabDeleteVisible = ''"
            >
              <div slot="prefix">
                <!--删除标签-->
                <i class="el-icon-error close-select-button" v-if="tabDeleteVisible === index" @click.stop="deleteTabSelect(index)"></i>
              </div>
              <el-option v-for="item in tabOptions" :key="item.tagId" :label="item.name" :value="item.tagId" style="width: 184px;">
                <span style="float: left;">{{ item.name }}</span>
                <span style="float: right;">
                  <i class="el-icon-check" v-if="tab === item.tagId" style="font-size: 4px;" />
                </span>
              </el-option>
            </el-select>
          </template>
          <i class="el-icon-circle-plus-outline add-select-button" v-if="formData.tabs.length < 15" @click="addTabSelect"></i>
        </div>
      </el-form-item>
      <el-form-item label="封面" class="cover-item">
        <el-upload
          class="cover"
          action=""
          ref="coverUpload"
          accept=".png,.jpg"
          :show-file-list="false"
          :http-request="handleImgSize"
          :on-change="handleChange"
        >
          <template v-if="imageUrl">
            <el-image class="cover" :src="imageUrl"></el-image>
            <div class="upload-update">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-shangchuan" />
              </svg>
              <p>上传</p>
            </div>
          </template>
          <template v-else>
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-shangchuan" />
            </svg>
            <p>上传</p>
          </template>
        </el-upload>
        <div class="upload-tip">
          <i class="el-icon-warning-outline" style="margin-right: 5px"></i>
          仅支持JPG、PNG格式，1mb以内的图片
        </div>
        <canvas id="uploadCanvas" width="32" height="32" style="display: none"></canvas>
      </el-form-item>
      <el-form-item label="描述" prop="description">
        <el-input v-model="formData.description" type="textarea" maxlength="200" show-word-limit placeholder="请输入描述" />
      </el-form-item>
    </el-form>
    <div class="footer" slot="footer">
      <el-button class="button" @click="visible = false">取消</el-button>
      <el-button type="primary" :loading="loading" class="button" @click="submit">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import YTIcon from '../YTIcon'
import questionBankApi from '@/api/questionBank'
import ossApi from '@api/oss'
import tagApi from '@/api/tag'
export default {
  name: 'QuestionBankCUDialog',
  components: { YTIcon },
  data() {
    const validateName = (rule, value, callback) => {
      let reg = /^(?=.*\S).+$/
      if (!reg.test(value)) {
        callback(new Error('题库名称不能全为空格'))
      } else {
        callback()
      }
    }
    return {
      visible: false,
      mode: true,
      loading: false,
      formData: {
        name: '',
        description: '',
        tabs: [''],
        cover: ''
      },
      ruleValidate: {
        name: [
          { required: true, message: '题库名称不能为空' },
          { max: 30, message: '题库名称长度不能超过30个字符' },
          { min: 1, message: '题库名称长度不小于1个字符' },
          { validator: validateName, trigger: 'blur' }
        ],
        description: [{ max: 200, message: '题库描述长度不能超过200个字符' }]
      },
      tabOptions: [],
      tabOptionsCopy: [],
      tabDeleteVisible: '',
      tabTipVisible: '',
      tabKeyword: '',
      imageUrl: '',
      uploadFile: {},
      uploadParams: {
        key: '',
        policy: '',
        OSSAccessKeyId: '',
        success_action_status: '200',
        Signature: ''
      }
    }
  },
  mounted() {
    this.getTabs()
  },
  methods: {
    initData(row) {
      //用空数组替代tabs, 避免浅拷贝
      this.formData = { ...row, ...{ tabs: [] } }
      this.imageUrl = row.cover
      if (row.tabs.length === 0) {
        this.$set(this.formData.tabs, 0, '')
        return
      }
      this.formData.tabs = row.tabs.map(item => {
        return item.tabId
      })
    },
    open(mode) {
      this.mode = mode
      this.visible = true
    },
    submit() {
      if (this.mode) {
        this.create()
      } else {
        this.update()
      }
    },
    create() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          let payload = { ...this.formData }
          questionBankApi
            .add(payload)
            .then(res => {
              if (res.res === true) {
                this.$message.success('添加成功')
                this.$emit('refresh')
                this.visible = false
              } else {
                this.loading = false
              }
            })
            .catch(err => {
              this.loading = false
            })
        }
      })
    },
    update() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          let payload = { ...this.formData }
          questionBankApi
            .update(payload, this.formData.questionBankId)
            .then(res => {
              if (res.res === true) {
                this.$message.success('修改成功')
                this.$emit('refresh')
                this.visible = false
              } else {
                this.loading = false
              }
            })
            .catch(err => {
              this.loading = false
            })
        }
      })
    },
    close(done) {
      done()
    },
    closed() {
      this.$refs.form.resetFields()
      this.formData.tabs = ['']
      this.imageUrl = ''
      this.formData.cover = ''
      this.loading = false
    },
    getTabs() {
      let payload = {
        keyword: '',
        typeId: this.ytConstant.tagType.QUESTION_BANK
      }
      tagApi.searchTagByTypeId(payload).then(res => {
        if (res.code === 0) {
          this.tabOptions = res.res
          this.tabOptionsCopy = res.res
        }
      })
    },
    addTabSelect() {
      if (this.formData.tabs[this.formData.tabs.length - 1] === '') {
        this.$message.warning('请先创建或选择上一个标签')
        return
      }
      this.formData.tabs.push('')
    },
    deleteTabSelect(index) {
      this.formData.tabs.splice(index, 1)
      if (this.formData.tabs.length === 0) {
        this.formData.tabs.push('')
      }
      this.$forceUpdate()
    },
    resetSelect(visible) {
      if (!visible) {
        this.tabOptions = this.tabOptionsCopy
      } else {
        this.tabOptions = this.tabOptions.filter(item => {
          return !this.formData.tabs.includes(item.tagId)
        })
      }
    },
    handleChange(file) {
      this.uploadFile = file
    },
    handleImgSize() {
      if (this.uploadFile.size > 1024000) {
        this.$message.warning('请上传小于1mb的图片')
        return
      }
      let fileName = this.uploadFile.name.slice(0, -4)
      fileName = fileName.replace(/[ |~`!@#$%^&*()\-_+=\\\[\]{};:"',<.>\/?，。；：“”》《、]/g, '')
      this.uploadFile.name = fileName + (this.uploadFile.raw.type === 'image/png' ? '.png' : '.jpg')
      let c = document.getElementById('uploadCanvas')
      let ctx = c.getContext('2d')
      let reader = new FileReader()
      reader.onloadend = e => {
        let img = new Image()
        img.src = e.target.result
        img.onload = () => {
          ctx.drawImage(img, 0, 0, 32, 32)
          let dataURL = this.uploadFile.raw.type === 'image/png' ? c.toDataURL('image/png') : c.toDataURL('image/jpeg')
          let file = this.$dataUrlToFile(dataURL, this.uploadFile.name)
          if (window.uploadUrl) {
            let formData = new FormData()
            formData.append('file', file, this.uploadFile.name)
            ossApi.upCover(formData).then(res => {
              this.imageUrl = res.res
              this.formData.cover = res.res
            })
          } else {
            ossApi.getQuestionBankCoverToken(new Date().valueOf(), this.uploadFile.name).then(res => {
              let data = res.res
              this.uploadParams.key = data.dir
              this.uploadParams.OSSAccessKeyId = data.accessId
              this.uploadParams.policy = data.policy
              this.uploadParams.Signature = data.signature
              let formData = new FormData()
              formData.append('key', this.uploadParams.key)
              formData.append('OSSAccessKeyId', this.uploadParams.OSSAccessKeyId)
              formData.append('policy', this.uploadParams.policy)
              formData.append('Signature', this.uploadParams.Signature)
              formData.append('file', file)
              fetch(`https://${data.host}`, {
                method: 'POST',
                body: formData
              }).then(result => {
                this.imageUrl = `https://${data.host}/${this.uploadParams.key}`
                this.formData.cover = `https://${data.host}/${this.uploadParams.key}`
              })
            })
          }
        }
      }
      reader.readAsDataURL(this.uploadFile.raw)
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/theme/variables';
.cover {
  width: 64px;
  height: 64px;
  ::v-deep .el-upload {
    p {
      height: 13px;
      line-height: 13px;
    }
  }
}
.upload-update {
  p {
    height: 13px;
    line-height: 13px;
  }
}
.upload-tip {
  margin-left: 76px;
}
</style>
