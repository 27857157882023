<template>
  <div class="yt-main">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item>题库管理</el-breadcrumb-item>
        <el-breadcrumb-item>题库列表</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="yt-header">
      <el-menu class="yt-menu" :default-active="activeIndex" mode="horizontal" @select="selectMenu">
        <el-menu-item index="1">学校</el-menu-item>
        <el-menu-item index="0">平台</el-menu-item>
      </el-menu>
      <div class="yt-header-toolbar">
        <el-input v-model="keyword" placeholder="通过名称/标签搜索题库" class="yt-search" @keydown.enter.native="reSearchQuestionBank">
          <i slot="suffix" class="el-input__icon el-icon-search" @click="reSearchQuestionBank" />
        </el-input>
        <el-select
          v-model="tabIds"
          v-if="activeIndex !== '0'"
          placeholder="搜索题库标签"
          class="yt-search-select"
          filterable
          clearable
          multiple
          :collapse-tags="tabIds.length > 2"
          @change="reSearchQuestionBank"
        >
          <el-option v-for="item in tagOptions" :key="item.tagId" :label="item.name" :value="item.tagId"> </el-option>
        </el-select>
        <el-button v-if="activeIndex !== '0'" type="primary" size="small" @click="toCreate">
          <div>
            <i class="el-icon-circle-plus-outline" />
            <p>新建题库</p>
          </div>
        </el-button>
        <el-button v-if="activeIndex !== '0'" size="small" @click="toDelete(false)">
          <div>
            <YTIcon :href="'#icon-shanchu'" />
            <p>删除题库</p>
          </div>
        </el-button>
      </div>
    </div>
    <div class="yt-container">
      <div class="yt-content">
        <el-table
          ref="questionBankTable"
          :data="questionBankList"
          v-loading="questionBankLoading"
          element-loading-text="加载中"
          class="yt-table"
          height="calc(100% - 35px)"
          @select="selectQuestionBank"
          @select-all="selectQuestionBank"
        >
          <el-table-column :class-name="activeIndex !== '0' ? 'el-table-column--selection' : 'close-checkbox'" type="selection" width="31" />
          <el-table-column label="题库名称" prop="name" show-overflow-tooltip />
          <el-table-column v-if="activeIndex !== '0'" label="创建者" prop="creatorName" show-overflow-tooltip />
          <el-table-column v-if="activeIndex !== '0'" label="创建时间" show-overflow-tooltip>
            <template v-if="activeIndex !== '0'" slot-scope="scope">{{ $formatTime(scope.row.createdTime, 'yyyy-MM-dd hh:mm') }}</template>
          </el-table-column>
          <el-table-column label="题目总数" prop="questionCount" show-overflow-tooltip />
          <el-table-column v-if="activeIndex !== '0'" label="草稿" prop="draftCount" show-overflow-tooltip />
          <el-table-column v-if="activeIndex !== '0'" label="未审核" show-overflow-tooltip>
            <template v-if="activeIndex !== '0'" slot-scope="scope">
              <span style="color: #FFC117;">{{ scope.row.unauditedCount }}</span>
            </template>
          </el-table-column>
          <el-table-column label="已审核" prop="publishedCount" show-overflow-tooltip />
          <el-table-column v-if="activeIndex !== '0'" label="题库标签" show-overflow-tooltip>
            <template v-if="activeIndex !== '0'" slot-scope="scope">
              <div v-if="scope.row.tabs.length > 0" class="tag-wrapper">
                <div class="tag-item">{{ scope.row.tabs[0].name }}</div>
                <el-popover
                  v-if="scope.row.tabs.length > 1"
                  placement="top"
                  title="题库标签"
                  width="184"
                  trigger="hover"
                  popper-class="yt-table-tag-popover"
                >
                  <div class="tag-list">
                    <div v-for="(tab, index) in scope.row.tabs" :key="index" class="tag-item">
                      {{ tab.name }}
                    </div>
                  </div>
                  <div slot="reference" class="dots">
                    <YTIcon :href="'#icon-gengduo1'"></YTIcon>
                  </div>
                </el-popover>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="描述" prop="description" show-overflow-tooltip />
          <el-table-column v-if="activeIndex !== '0'" label="操作" width="80px">
            <template slot-scope="scope">
              <span class="tool-button" @click="toUpdate(scope.row)">修改</span>
              <span class="tool-button" @click="toDelete(true, scope.row.questionBankId)">删除</span>
            </template>
          </el-table-column>
          <div slot="empty" id="empty-slot">
            <template v-if="!questionBankLoading">
              <YTIcon :href="'#icon-zanwushuju'" style="font-size: 100px" />
            </template>
          </div>
        </el-table>
        <Page
          class="yt-page"
          :class="'yt-page-' + (total.toString().length > 1 ? total.toString().length : 2)"
          :total="total"
          :current="pageNum + 1"
          :page-size-opts="[10, 20, 40, 100]"
          :page-size="pageSize"
          show-elevator
          show-sizer
          show-total
          @on-change="changePage"
          @on-page-size-change="changePageSize"
        />
      </div>
    </div>
    <QuestionBankCUDialog ref="questionBankCUDialog" @refresh="searchQuestionBank" />
    <ConfirmDialog ref="deleteDialog" :title="'删除题库'" @on-ok="deleteQuestionBank">
      <div style="width: 219px; margin: 0 auto;">
        <YTIcon :href="'#icon-jingshi'" style="font-size: 18px;" />
        是否确认删除该题库，删除后将不可恢复
      </div>
    </ConfirmDialog>
  </div>
</template>

<script>
import YTIcon from '@components/common/YTIcon'
import QuestionBankCUDialog from '@components/common/dialog/QuestionBankCUDialog'
import ConfirmDialog from '@components/common/dialog/ConfirmDialog'
import questionBankApi from '@/api/questionBank'
import tagApi from '@api/tag'
export default {
  name: 'QuestionBank',
  components: { YTIcon, QuestionBankCUDialog, ConfirmDialog },
  data() {
    return {
      activeIndex: '1',
      keyword: '',
      tabIds: [],
      questionBankList: [],
      questionBankLoading: true, //是否加载中
      pageNum: 0,
      pageSize: 10,
      total: 0,
      tagOptions: [],
      selectedQuestionBanks: []
    }
  },
  mounted() {
    this.searchQuestionBank()
    this.getTags()
  },
  methods: {
    searchQuestionBank() {
      let payload = {
        name: this.keyword,
        tabIds: this.tabIds,
        type: this.activeIndex
      }
      this.questionBankLoading = true
      questionBankApi
        .search(payload, this.pageNum, this.pageSize)
        .then(res => {
          this.questionBankList = res.res.data
          this.total = res.res.total
        })
        .finally(() => {
          this.$refs.questionBankTable.doLayout()
          this.questionBankLoading = false
        })
    },
    reSearchQuestionBank() {
      this.pageNum = 0
      this.searchQuestionBank()
    },
    toCreate() {
      this.$refs.questionBankCUDialog.open(true)
    },
    toUpdate(row) {
      this.$refs.questionBankCUDialog.open(false)
      this.$nextTick(() => {
        this.$refs.questionBankCUDialog.initData(row)
      })
    },
    changePage(pageNum) {
      this.pageNum = pageNum - 1
      this.searchQuestionBank()
    },
    changePageSize(pageSize) {
      this.pageSize = pageSize
      this.searchQuestionBank()
    },
    getTags() {
      let payload = {
        keyword: '',
        typeId: this.ytConstant.tagType.QUESTION_BANK
      }
      tagApi.searchTagByTypeId(payload).then(res => {
        if (res.code === 0) {
          this.tagOptions = res.res
        }
      })
    },
    selectMenu(index) {
      this.activeIndex = index
      this.questionBankList = []
      this.keyword = ''
      this.searchQuestionBank()
    },
    selectQuestionBank(selection) {
      this.selectedQuestionBanks = selection.map(item => {
        return item.questionBankId
      })
    },
    toDelete(isSingle, id) {
      if (isSingle) {
        this.selectedQuestionBanks = [id]
      } else {
        if (this.selectedQuestionBanks.length === 0) {
          this.$message.warning('请先勾选要删除的题库')
          return
        }
      }
      this.$refs.deleteDialog.open()
    },
    deleteQuestionBank() {
      questionBankApi.batchDelete(this.selectedQuestionBanks).then(res => {
        if (res.res === true) {
          this.$message.success('删除成功')
          this.selectedQuestionBanks = []
          this.searchQuestionBank()
        }
      })
    }
  }
}
</script>

<style lang="less" scoped></style>
